'use client';

import React from 'react';
import { useAtom } from 'jotai';
import Button, { ButtonIconPosition } from '@/components/Button';
import Modal from '@/components/Modal';
import { isThankYouOpen } from '@/store/contactModal';
import styles from './ThankYouModal.module.scss';

const ThankYouModal = () => {
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useAtom(isThankYouOpen);

  return isThankYouModalOpen && (
    <Modal
      className={styles.modal}
      closeButton={(
        <button
          type='button'
          className={styles.closeButton}
          onClick={() => setIsThankYouModalOpen(false)}
        >
          <svg>
            <use
              xlinkHref='/media/close.svg#closeSVG'
              href='/media/close.svg#closeSVG'
            />
          </svg>
        </button>
      )}
    >
      <div className={styles.vector}>
        <img
          src='/media/vector.svg'
          alt=''
        />
      </div>
      <div className={styles.vector2}>
        <img
          src='/media/vector.svg'
          alt=''
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2 className={styles.title}>Thank You!</h2>
          <p className={styles.subtitle}>Your application has been successfully submitted. Please await. We will contact you shortly. </p>
          <Button
            className={styles.button}
            onClick={() => setIsThankYouModalOpen(false)}
            icon={(
              <svg className={styles.spinner}>
                <use
                  xlinkHref='/media/angle_left.svg#angleLeftSVG'
                  href='/media/angle_left.svg#angleLeftSVG'
                />
              </svg>
            )}
            iconPosition={ButtonIconPosition.LEFT}
            iconSize={{ width: 20, height: 20 }}
          >
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
