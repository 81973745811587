'use client';

import React from 'react';
import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import { siteMenu } from '@/siteNavigation';
import styles from './Header.module.scss';

const HeaderMenu = () => {
  const pathname = usePathname();

  return (
    <ul className={styles.menu}>
      {siteMenu.map(item => (
        <li key={item.id}>
          <Link
            className={classNames(styles.menu__link, {
              [styles.active]: pathname.includes(item.url),
            })}
            href={item.url}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default HeaderMenu;
