'use client';

import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import Script from 'next/script';
import isCookiesAccepted from '@/store/cookies';
import isContactOpen, { isThankYouOpen } from '@/store/contactModal';
import { USER_CONSENT_COOKIE_KEY } from '../../../constants';

const Init = () => {
  const isContactsModalOpen = useAtomValue(isContactOpen);
  const isThankYouModalOpen = useAtomValue(isThankYouOpen);

  const setCookiesAccepted = useSetAtom(isCookiesAccepted);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isAccepted = localStorage.getItem(USER_CONSENT_COOKIE_KEY) === '1';
      setCookiesAccepted(isAccepted);
    }
  }, []);

  const setRealViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  useEffect(() => {
    setRealViewportHeight();
    window.addEventListener('resize', setRealViewportHeight);

    return () => {
      window.removeEventListener('resize', setRealViewportHeight);
    };
  }, []);

  useEffect(() => {
    document.documentElement.style.overflowY = (isContactsModalOpen || isThankYouModalOpen) ? 'hidden' : 'unset';
  }, [isContactsModalOpen, isThankYouModalOpen]);

  return (
    <>
      <Script
          // strategy='beforeInteractive'
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WCQWRJNW');`,
        }}
      />
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-WCQWRJNW'
          height='0'
          width='0'
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
    </>
  );
};

export default Init;
