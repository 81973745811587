'use client';

import React from 'react';
import classNames from 'classnames';
import Portal from '@/components/Portal';
import styles from './Modal.module.scss';

type ModalProps = {
  children: React.ReactNode,
  className?: string,
  closeButton: React.ReactNode,
};
const Modal = ({ children, className, closeButton }: ModalProps) => (
  <Portal>
    <div className={classNames(styles.modal, className)}>
      {closeButton}
      {children}
    </div>
  </Portal>
);

export default Modal;
